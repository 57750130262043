import React from "react";

import Welcome from "./Interface/Components/Welcome";

import "./App.css";

function App() {
  return <Welcome />;
}

export default App;
